import React from 'react'

import {
  Heading,
  Paragraph,
  SomeButton,
  SomeButtons
} from '@te-digi/styleguide'

import { Code } from '../../components/Code'
import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { LinkMockTargetBlank } from '../../components/LinkMock'
import { Section } from '../../components/Section'
import IconHTMLExample from '../../examples/html/IconHTMLExample'

export const SomeButtonExample1 = () => (
  <>
    <Heading
      align="center"
      level={2}
      size={5}
    >
      Seuraa meitä:
    </Heading>
    <SomeButtons align="center">
      <SomeButton
        href="#"
        name="x"
      />
      <SomeButton
        href="#"
        name="linkedin"
      />
      <SomeButton
        href="#"
        name="youtube"
      />
    </SomeButtons>
  </>
)

export const SomeButtonHTMLExample1 = () => (
  <>
    <h2 className="h5 text-center">Seuraa meitä:</h2>
    <ul className="some-buttons some-buttons-center">
      <li className="some-buttons-item">
        <LinkMockTargetBlank className="some-button some-button-x-twitter some-button-primary">
          <IconHTMLExample
            name="TwitterFillIcon"
            size="lg"
          />
          <span className="visually-hidden">X, avautuu uuteen välilehteen</span>
        </LinkMockTargetBlank>
      </li>
      <li className="some-buttons-item">
        <LinkMockTargetBlank className="some-button some-button-linkedin some-button-primary">
          <IconHTMLExample
            name="LinkedinFillIcon"
            size="lg"
          />
          <span className="visually-hidden">
            LinkedIn, avautuu uuteen välilehteen
          </span>
        </LinkMockTargetBlank>
      </li>
      <li className="some-buttons-item">
        <LinkMockTargetBlank className="some-button some-button-youtube some-button-primary">
          <IconHTMLExample
            name="YoutubeFillIcon"
            size="lg"
          />
          <span className="visually-hidden">
            YouTube, avautuu uuteen välilehteen
          </span>
        </LinkMockTargetBlank>
      </li>
    </ul>
  </>
)

export const SomeButtonExample2 = () => (
  <SomeButtons>
    <SomeButton
      href="#"
      color="colored"
      name="facebook"
    />
    <SomeButton
      href="#"
      color="colored"
      name="x"
    />
    <SomeButton
      href="#"
      color="colored"
      name="instagram"
    />
    <SomeButton
      href="#"
      color="colored"
      name="youtube"
    />
    <SomeButton
      href="#"
      color="colored"
      name="linkedin"
    />
  </SomeButtons>
)

export const SomeButtonHTMLExample2 = () => (
  <ul className="some-buttons">
    <li className="some-buttons-item">
      <LinkMockTargetBlank className="some-button some-button-facebook some-button-colored">
        <IconHTMLExample
          name="FacebookFillIcon"
          size="lg"
        />
        <span className="visually-hidden">
          Facebook, avautuu uuteen välilehteen
        </span>
      </LinkMockTargetBlank>
    </li>
    <li className="some-buttons-item">
      <LinkMockTargetBlank className="some-button some-button-x-twitter some-button-colored">
        <IconHTMLExample
          name="TwitterFillIcon"
          size="lg"
        />
        <span className="visually-hidden">X, avautuu uuteen välilehteen</span>
      </LinkMockTargetBlank>
    </li>
    <li className="some-buttons-item">
      <LinkMockTargetBlank className="some-button some-button-instagram some-button-colored">
        <IconHTMLExample
          name="InstagramFillIcon"
          size="lg"
        />
        <span className="visually-hidden">
          Instagram, avautuu uuteen välilehteen
        </span>
      </LinkMockTargetBlank>
    </li>
    <li className="some-buttons-item">
      <LinkMockTargetBlank className="some-button some-button-youtube some-button-colored">
        <IconHTMLExample
          name="YoutubeFillIcon"
          size="lg"
        />
        <span className="visually-hidden">
          YouTube, avautuu uuteen välilehteen
        </span>
      </LinkMockTargetBlank>
    </li>
    <li className="some-buttons-item">
      <LinkMockTargetBlank className="some-button some-button-linkedin some-button-colored">
        <IconHTMLExample
          name="LinkedinFillIcon"
          size="lg"
        />
        <span className="visually-hidden">
          LinkedIn, avautuu uuteen välilehteen
        </span>
      </LinkMockTargetBlank>
    </li>
  </ul>
)

export const SomeButtonExample3 = () => (
  <>
    <h2 className="text-center h5 text-white">Seuraa meitä:</h2>
    <SomeButtons align="center">
      <SomeButton
        href="#"
        color="white"
        name="x"
      />
      <SomeButton
        href="#"
        color="white"
        name="linkedin"
      />
      <SomeButton
        href="#"
        color="white"
        name="youtube"
      />
    </SomeButtons>
  </>
)

export const SomeButtonHTMLExample3 = () => (
  <>
    <h2 className="text-center h5 text-white">Seuraa meitä:</h2>
    <ul className="some-buttons some-buttons-center">
      <li className="some-buttons-item">
        <LinkMockTargetBlank className="some-button some-button-x-twitter some-button-white">
          <IconHTMLExample
            name="TwitterFillIcon"
            size="lg"
          />
          <span className="visually-hidden">X, avautuu uuteen välilehteen</span>
        </LinkMockTargetBlank>
      </li>
      <li className="some-buttons-item">
        <LinkMockTargetBlank className="some-button some-button-linkedin some-button-white">
          <IconHTMLExample
            name="LinkedinFillIcon"
            size="lg"
          />
          <span className="visually-hidden">
            LinkedIn, avautuu uuteen välilehteen
          </span>
        </LinkMockTargetBlank>
      </li>
      <li className="some-buttons-item">
        <LinkMockTargetBlank className="some-button some-button-youtube some-button-white">
          <IconHTMLExample
            name="YoutubeFillIcon"
            size="lg"
          />
          <span className="visually-hidden">
            YouTube, avautuu uuteen välilehteen
          </span>
        </LinkMockTargetBlank>
      </li>
    </ul>
  </>
)

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="SomeButton"
    components={[
      { component: SomeButton, restElement: 'component' },
      { component: SomeButtons }
    ]}
    status={[{ type: 'accessible', version: '15.1.0' }]}
  >
    <Section>
      <Paragraph>
        Sosiaalisen median linkit avautuvat uuteen välilehteen.
        React-komponentti tuottaa automaattisesti oikenalaisen linkin.
        Verkkopalvelussa tulee huolehtia, että linkkiin on määritelty{' '}
        <Code>target=&quot;_blank&quot;</Code> sekä{' '}
        <Code>rel=&quot;noopener noreferrer&quot;</Code>.
      </Paragraph>
      <Playground>{SomeButtonExample1()}</Playground>
      <Playground format="html">{SomeButtonHTMLExample1()}</Playground>
      <Playground>{SomeButtonExample2()}</Playground>
      <Playground format="html">{SomeButtonHTMLExample2()}</Playground>
      <Playground isDark>{SomeButtonExample3()}</Playground>
      <Playground
        format="html"
        isDark
      >
        {SomeButtonHTMLExample3()}
      </Playground>
    </Section>
    <Section title="Saavutettavuus">
      <Paragraph>
        Jokaisella ikonilla pitää olla määritetty nimi, joka kertoo somepalvelun
        nimen ja linkin pitää ohjata somepalveluun. Linkin tulee avautua uuteen
        välilehteen, ja tämän tulee käydä ilmi myös ikonilinkin label-tekstistä.
      </Paragraph>
    </Section>
  </Content>
)

export default Page
